import React from "react";
import Layout from "../components/Layout";
import * as styles from "../css/error.module.css"
import {Link } from "gatsby"
import Banner from "../components/Banner";
import Seo    from "../components/SEO";    

export default function error(){
    return (
        <div>
            <Layout>
                <Seo title="Error"/>
                <header className={styles.error}>
                    <Banner title="oops looks like the page does not exist">
                        <Link to="/" className="btn-white">
                            back to home page
                        </Link>
                    </Banner>
                </header>
                
            </Layout>
        </div>
    )
}